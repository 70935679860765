import React, { useState, useCallback, useEffect } from "react";
import Navbar from "../../components/user/navbar";
import Footer from "../../components/main/footer";
import { Grid2, Typography, Box, Button } from "@mui/material";
import hostname from "../../hostname";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RocketLaunch } from "@mui/icons-material";

const Plan = () => {
  const [plan, setPlan] = useState(null);

  const redirectToLogin = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  const handleRefreshToken = useCallback(async () => {
    try {
      const response = await fetch(`${hostname}/api/v1/user/auth/refresh`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.ok) {
        const { accessToken } = await response.json();
        localStorage.setItem("accessToken", accessToken);
        return accessToken;
      } else {
        redirectToLogin();
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      redirectToLogin();
    }
  }, []);

  const fetchPlan = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        redirectToLogin();
        return;
      }

      let response = await fetch(`${hostname}/api/v1/user/tarif/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 401 || response.status === 403) {
        const newAccessToken = await handleRefreshToken();
        if (newAccessToken) {
          response = await fetch(`${hostname}/api/v1/user/tarif/get`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${newAccessToken}`,
            },
          });
        } else {
          throw new Error("Failed to refresh token");
        }
      }

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      const plan = await response.json();
      setPlan(plan);
    } catch (error) {
      console.error("Error while fetching plan :", error);
      toast.error("Error while fetching plan.");
    }
  }, [handleRefreshToken]);

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  return (
    <div>
      <Navbar />
      {plan && (
        <Grid2>
          <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
            <Typography
              fontFamily="General Sans Regular"
              variant="body2"
              fontWeight="700"
              fontSize="30px"
              marginTop="20px"
            >
              Your Uses
            </Typography>
          </Grid2>
          <Grid2>
            <Grid2 container spacing={3} justifyContent="space-evenly">
              <Grid2 item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    height: "200px",
                    minWidth: "250px",
                    backgroundColor: "#342DBF",
                    borderRadius: 4,
                    padding: "30px",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    color: "white",
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Typography
                    fontFamily="General Sans Regular"
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                    }}
                  >
                    {plan.tarif}
                  </Typography>

                  <Typography
                    fontFamily="General Sans Regular"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    Credits
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    height: "200px",
                    minWidth: "250px",
                    backgroundColor: "#A41243",
                    borderRadius: 4,
                    padding: "30px",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    color: "white",
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Typography
                    fontFamily="General Sans Regular"
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                    }}
                  >
                    {plan.utilisation}
                  </Typography>

                  <Typography
                    fontFamily="General Sans Regular"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    Uses
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 item xs={12} sm={6} md={4} marginBottom="30px">
                <Box
                  sx={{
                    height: "200px",
                    minWidth: "250px",
                    backgroundColor: "#9D1CB9",
                    borderRadius: 4,
                    padding: "30px",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    color: "white",
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Typography
                    fontFamily="General Sans Regular"
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                    }}
                  >
                    {plan.remaining}
                  </Typography>

                  <Typography
                    fontFamily="General Sans Regular"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    Remaining
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            {" "}
            <Button
              type="submit"
              href="plan/offer"
              variant="contained"
              startIcon={<RocketLaunch />}
              sx={{
                mt: 3,
                mb: 2,
                minWidth: "300px",
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  transform: "scale(1.05)",
                },
              }}
            >
              Recharge
            </Button>
          </Grid2>
        </Grid2>
      )}
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Plan;
