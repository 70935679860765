import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import Mail from "../../../images/mail_not_sent.svg";
import { Typography, Grid2 } from "@mui/material";

const MailNotSent = () => {
  return (
    <div>
      <div sx={{ minHeight: "100vh" }}>
        <Navbar />
        <Grid2 bgcolor="#f8f9fc">
          <Grid2 container justifyContent="space-evenly" padding="50px">
            <Grid2 xs={6}>
              <img
                src={`${Mail}`}
                height="300px"
                width="350px"
                alt="Success!"
              />
            </Grid2>
            <Grid2 xs={6} width="500px" alignContent="center" spacing={2}>
              <Typography
                fontFamily="General Sans Regular"
                variant="h3"
                fontSize="20px"
                color="#5271ff"
                flexWrap="wrap"
                display="flex"
              >
                {" "}
                ERROR!{" "}
              </Typography>

              <Typography
                fontFamily="General Sans Regular"
                variant="body2"
                color="#5271ff"
              >
                {" "}
                Your mail was not sent!{" "}
              </Typography>

              <Typography
                fontFamily="General Sans Regular"
                variant="body2"
                color="#5271ff"
              >
                {" "}
                We apologize for this inconvenience!{" "}
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </div>

      <Footer />
    </div>
  );
};

export default MailNotSent;
