import React from "react";
import Information from "../../components/user/profile";
import Navbar from "../../components/user/navbar";
import Footer from "../../components/main/footer";
import { Grid2, Typography } from "@mui/material";
import ProfileImage from "../../images/profile.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CVprofile from "../../components/user/cv";

const Profile = () => {
  return (
    <div>
      <Navbar />
      <Grid2
        container
        bgcolor="#f8f9fc"
        flexDirection="column"
        alignItems="center"
        flexWrap="wrap"
        p={6}
        px={6}
      >
        <Grid2 marginBottom="24px">
          <img
            src={`${ProfileImage}`}
            height="200px"
            width="250px"
            alt="Success!"
          />
        </Grid2>
        <Grid2 marginBottom="23px">
          <Typography fontFamily="General Sans Regular" variant="h3" fontWeight="700" fontSize="30px">
            Your profile
          </Typography>
        </Grid2>

        <Grid2 marginBottom="23px">
          <CVprofile />
        </Grid2>

        <Grid2
          container
          gap={3}
          maxWidth="800px"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Information />
        </Grid2>
      </Grid2>

      <Footer />
      <ToastContainer theme="colored" />
    </div>
  );
};

export default Profile;
