import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Grid2, Typography, Button } from "@mui/material";

const Recharge = () => {
  return (
    <div>
      <Navbar />
      <Grid2
        container
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
        bgcolor="#f8f9fc"
        flexDirection="column"
      >
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography variant="body2" fontWeight="700" fontSize="30px">
            How to recharge 💵
          </Typography>
        </Grid2>

        <iframe
          width="100%"
          height="300px"
          src="https://www.youtube.com/embed/FIUHYJTEaEQ"
          title="How to recharge your credits"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            maxWidth: "1000px",
            borderRadius: "8px",
          }}
        ></iframe>
        <Grid2 container justifyContent="center" width="300px">
          <Button
          fullWidth
            href="/login"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              color:"white",
              backgroundColor: "#5271ff",
              "&:hover": { backgroundColor: "#004aad" },
            }}
          >
            LogIn
          </Button>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  );
};

export default Recharge;
