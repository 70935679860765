import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Grid2, Typography, Card, CardContent, Button } from "@mui/material";
import Logo from "../../../images/icon.png";
const Demo = () => {
  return (
    <div>
      <Navbar />
      <Grid2
        container
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
        bgcolor="#f8f9fc"
        flexDirection="column"
      >
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography variant="body2" fontWeight="700" fontSize="30px">
            Presentation
          </Typography>
        </Grid2>

        <iframe
          width="100%"
          height="400px"
          src="https://www.youtube.com/embed/Bf396oJktWY"
          title="SmartCandidate"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            maxWidth: "1000px",
            borderRadius: "8px",
          }}
        ></iframe>
      </Grid2>

      <Grid2
        container
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
        bgcolor="#f8f9fc"
        flexDirection="column"
      >
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography variant="body2" fontWeight="700" fontSize="30px">
            Tutorials
          </Typography>
        </Grid2>

        <Grid2 gap={2} width="100%">
          {" "}
          <Grid2
            container
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
              <Typography variant="h5" fontSize="20px">
                How to install SmartCandidate
              </Typography>
            </Grid2>
            <Grid2
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid2 width="80%" maxWidth="1000px">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/n0ARzaqduUY"
                  title="How to install SmartCandidate"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "8px" }}
                ></iframe>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            container
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Grid2
              textAlign="center"
              alignItems="center"
              marginBottom="20px"
              marginTop="20px"
            >
              <Typography variant="h5" fontSize="20px">
                How to generate cover letter
              </Typography>
            </Grid2>
            <Grid2
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid2 width="80%" maxWidth="1000px">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/iGHVN9MicDY"
                  title="How to generate cover letter"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "8px" }}
                ></iframe>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            container
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Grid2
              textAlign="center"
              alignItems="center"
              marginBottom="20px"
              marginTop="20px"
            >
              <Typography variant="h5" fontSize="20px">
                How to recharge your credits 💵
              </Typography>
            </Grid2>
            <Grid2
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid2 width="80%" maxWidth="1000px">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/FIUHYJTEaEQ"
                  title="How to recharge your credits"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "8px" }}
                ></iframe>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            container
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Grid2
              textAlign="center"
              alignItems="center"
              marginBottom="20px"
              marginTop="20px"
            >
              <Typography variant="h5" fontSize="20px">
                How to fix missing resume file 📝
              </Typography>
            </Grid2>
            <Grid2
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid2 width="80%" maxWidth="1000px">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/5b4rn1N3Xi4"
                  title="How to fix missing resume file"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "8px" }}
                ></iframe>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            container
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <Grid2
              textAlign="center"
              alignItems="center"
              marginBottom="20px"
              marginTop="20px"
            >
              <Typography variant="h5" fontSize="20px">
                How to fix refresh 🔄
              </Typography>
            </Grid2>
            <Grid2
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid2 width="80%" maxWidth="1000px">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/tRcpI4Pkm_c"
                  title="How to fix refresh"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    borderRadius: "8px",
                  }}
                ></iframe>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2
        container
        bgcolor="#f8f9fc"
        flexDirection="column"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
      >
        <Grid2 marginBottom="20px">
          <Typography variant="body2" fontWeight="700" fontSize="30px">
            Badges
          </Typography>
        </Grid2>
        <Grid2 container flexDirection="row" gap={3} padding="30px">
          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
                cursor: "auto",
              },
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Missing CV 📝
              </Typography>
              <Typography variant="body2" color="inherit">
                If you're seeing this notification, it means you haven't
                uploaded your resume yet. Please upload it to allow
                SmartCandidate to generate a tailored cover letter for you
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
                cursor: "auto",
              },
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Refresh 🔄
              </Typography>
              <Typography variant="body2" color="inherit">
                If you see the refresh badge, it means you need to log in again
                through the extension interface to continue using
                SmartCandidate. Please log in to proceed{" "}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
                cursor: "auto",
              },
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Billing 💵
              </Typography>
              <Typography variant="body2" color="inherit">
                If you encounter this badge, it means you've reached your usage
                limit for SmartCandidate. Please recharge your plan to continue{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Grid2
        container
        bgcolor="#f8f9fc"
        flexDirection="column"
        flexWrap="wrap"
        padding="30px"
        justifyContent="space-evenly"
      >
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography variant="body2" fontWeight="700" fontSize="30px">
            SmartCandidate
          </Typography>
        </Grid2>

        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Grid2>
            <img src={`${Logo}`} height="150px" width="175px" alt="logo" />
          </Grid2>

          <Grid2 maxWidth="500px">
            {" "}
            <Typography variant="body2" marginBottom="10px">
              🌟 Introducing SmartCandidate: Your Career Partner!
            </Typography>
            <Typography variant="body2" marginBottom="10px">
              🌟 We’re excited to officially launch SmartCandidate, a
              revolutionary solution designed to simplify and supercharge your
              job applications!
            </Typography>
            <Typography variant="body2" marginBottom="10px">
              🚀 🎯 What is SmartCandidate? SmartCandidate helps you create
              tailored cover letters in just a few clicks. Simply upload your
              CV, select a job description, and let SmartCandidate craft a
              personalized letter that highlights your strengths and matches the
              role perfectly.
            </Typography>
            <Typography variant="body2" marginBottom="10px">
              💡 Why choose SmartCandidate? Generate professional cover letters
              in English, French, or Spanish. Save time while creating impactful
              applications. Stand out with content that reflects your unique
              skills and experience.
            </Typography>
            <Typography variant="body2" marginBottom="10px">
              💬 We’d love to hear your thoughts! What do you struggle with most
              when applying for jobs?
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 textAlign="center">
          {" "}
          <Button
            type="submit"
            href="/contact-us"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              width: "300px",
              fontWeight: "700",
              color: "white",
              backgroundColor: "#5271ff",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                color: "white",
                backgroundColor: "#004aad",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            Contact
          </Button>
        </Grid2>
      </Grid2>
      <Footer />
    </div>
  );
};

export default Demo;
