import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import {
  Grid2,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Container,
  Box,
} from "@mui/material";
import Time from "../../../images/time.svg";
import Upload from "../../../images/upload_resume.svg";
import Icon from "../../../images/icon.png";
import World from "../../../images/world.jpg";
import SaveTime from "../../../images/time.jpg";
import Resume from "../../../images/resume.jpg";
import Logo from "../../../images/icon.png";
const landingPage = () => {
  const offers = [
    {
      title: "15 cover letter",
      price: 1.99,
      id: "price_1Qa5ZkCOl27OIxPKteBo9Elx",
      description: "Starter Pack",
      color: "#342DBF",
      gradient: "linear-gradient(135deg, #342DBF, #5C4FBF)",
      presentation:
        "Perfect for quick job applications, the Starter Pack includes 15 personalized cover letters for just $1.99. Ideal for those who need a simple and efficient solution.",
    },
    {
      title: "30 cover letter",
      price: 3.99,
      id: "price_1Qa5cBCOl27OIxPKXDBuRGq2",
      description: "Standard Pack",
      color: "#A41243",
      gradient: "linear-gradient(135deg, #A41243, #C03F5A)",
      presentation:
        "The Standard Pack offers 30 tailored cover letters for only $3.99. Designed for those applying to multiple jobs, it’s the perfect balance of value and flexibility.",
    },
    {
      title: "50 cover letter",
      price: 6.99,
      id: "price_1Qa5drCOl27OIxPKWgDsrYxu",
      description: "Pro Pack",
      color: "#9D1CB9",
      gradient: "linear-gradient(135deg, #9D1CB9, #B04FC3)",
      presentation:
        "For regular job seekers, the Pro Pack provides 50 customized cover letters at $6.99. A comprehensive solution to keep your applications impactful.",
    },
  ];

  return (
    <div>
      <Navbar />
      <Grid2
        container
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
        bgcolor="#f8f9fc"
        flexDirection="column"
      >
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography
            fontFamily="General Sans Regular"
            variant="body2"
            fontWeight="700"
            fontSize="30px"
          >
            SmartCandidate
          </Typography>
        </Grid2>

        <Grid2
          container
          justifyContent="space-evenly"
          flexDirection="row"
          wrap="wrap"
          gap={2}
          sx={{ width: "100%" }}
        >
          <Grid2
            xs={12}
            sm={9}
            sx={{
              width: {
                xs: "100%",
                sm: "80%",
                lg: "60%",
              },
            }}
          >
            {" "}
            <iframe
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/Bf396oJktWY"
              title="SmartCandidate"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                maxWidth: "1000px",
                borderRadius: "8px",
              }}
            ></iframe>
          </Grid2>
          <Grid2 container xs={12} sm={3} width="270px" justifyContent="center">
            <img src={`${Logo}`} height="200px" width="250px" alt="Success!" />
            <Typography
              fontFamily="General Sans Regular"
              color="#5271ff"
              fontWeight="700"
              textAlign="center"
              paddingTop="20px"
            >
              Stand out with cover letters crafted to highlight your skills and
              experience, designed to meet recruiter expectations seamlessly.
            </Typography>
            <Button
              type="submit"
              fullWidth
              href="/signup"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              Signup
            </Button>
            <Button
              fullWidth
              href="https://chromewebstore.google.com/detail/smartcandidate/jgccmimjdjmhacdmjilocpikbacfbhlc"
              target="_blank"
              variant="contained"
              sx={{
                mt: 1,
                mb: 2,
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              Install
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 padding="50px" bgcolor="#f8f9fc">
        <Container>
          <Typography
            fontFamily="General Sans Regular"
            textAlign="center"
            variant="h4"
            gutterBottom
            fontWeight="700"
          >
            Choose your plan
          </Typography>
          <Typography
            fontFamily="General Sans Regular"
            textAlign="center"
            variant="body2"
            gutterBottom
            fontWeight="700"
            marginBottom="10px"
          >
            Not a subscription, Pay per use!
          </Typography>

          <Grid2 container spacing={3} justifyContent="space-evenly">
            {offers
              .filter((offer) => offer.price !== "FREE")
              .map((offer) => (
                <Grid2 item key={offer.id} xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      height: "430px",
                      background: offer.gradient,
                      borderRadius: 4,
                      padding: "30px",
                      textAlign: "center",
                      alignContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                      color: "white",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <Typography
                      fontFamily="General Sans Regular"
                      variant="h5"
                      sx={{ marginBottom: "10px" }}
                    >
                      {offer.title}
                    </Typography>
                    <Typography
                      fontFamily="General Sans Regular"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "20px",
                      }}
                    >
                      ${offer.price}
                    </Typography>

                    <Typography
                      fontFamily="General Sans Regular"
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      {offer.description}
                    </Typography>
                    <Grid2 container justifyContent="center" display="flex">
                      <Typography
                        fontFamily="General Sans Regular"
                        variant="body2"
                        sx={{
                          maxWidth: "200px",
                          color: "white",
                          marginBottom: "20px",
                        }}
                      >
                        {offer.presentation}
                      </Typography>
                    </Grid2>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mt: 1,
                        mb: 2,
                        padding: "10px 25px 10px 25px",
                        fontWeight: "700",
                        color: offer.color,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                      href="/signup"
                    >
                      Choose
                    </Button>
                  </Box>
                </Grid2>
              ))}
          </Grid2>
        </Container>
      </Grid2>
      <Grid2
        container
        bgcolor="#f8f9fc"
        flexDirection="column"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
      >
        <Grid2 marginBottom="20px">
          <Typography
            fontFamily="General Sans Regular"
            variant="h3"
            fontWeight="700"
            fontSize="30px"
          >
            Services
          </Typography>
        </Grid2>
        <Grid2 container flexDirection="row" gap={3} padding="30px">
          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardMedia component="img" height="180" image={`${Resume}`} />
            <CardContent>
              <Typography
                fontFamily="General Sans Regular"
                gutterBottom
                variant="h5"
                component="div"
              >
                Personalized Cover Letters
              </Typography>
              <Typography
                fontFamily="General Sans Regular"
                variant="body2"
                color="inherit"
              >
                SmartCandidate crafts tailored cover letters that align
                perfectly with each job description. By analyzing your
                experiences and matching them to the position, our tool
                optimizes your application for maximum impact, helping you stand
                out to recruiters.
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardMedia component="img" height="180" image={`${SaveTime}`} />
            <CardContent>
              <Typography
                fontFamily="General Sans Regular"
                gutterBottom
                variant="h5"
                component="div"
              >
                Quick and Easy to Use
              </Typography>
              <Typography
                fontFamily="General Sans Regular"
                variant="body2"
                color="inherit"
              >
                In just a few clicks, SmartCandidate generates high-quality
                application documents without the hassle. Our intuitive design
                and simple interface let you create polished cover letters in
                seconds, saving you time and effort in your job search.
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardMedia component="img" height="180" image={`${World}`} />
            <CardContent>
              <Typography
                fontFamily="General Sans Regular"
                gutterBottom
                variant="h5"
                component="div"
              >
                Multilingual Support
              </Typography>
              <Typography
                fontFamily="General Sans Regular"
                variant="body2"
                color="inherit"
              >
                SmartCandidate is available in multiple languages, allowing you
                to create professional documents suited to the region and
                language of the job. Whether you’re applying locally or
                internationally, your cover letter will meet the specific
                expectations of each job market.
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Grid2 container bgcolor="#f8f9fc" flexDirection="column" flexWrap="wrap">
        <Grid2 marginBottom="20px" marginTop="20px">
          <Typography
            fontFamily="General Sans Regular"
            variant="h3"
            fontWeight="700"
            fontSize="30px"
            textAlign="center"
          >
            Why SmartCandidate ?
          </Typography>
        </Grid2>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          padding="20px"
          justifyContent="space-evenly"
        >
          <Grid2 xs={6} marginBottom="20px">
            <Typography
              fontFamily="General Sans Regular"
              sx={{ marginTop: "20px" }}
              variant="h3"
              fontSize="30px"
            >
              Save Time, Increase Opportunities
            </Typography>
            <Typography
              fontFamily="General Sans Regular"
              variant="body1"
              maxWidth="500px"
            >
              Applying for jobs can be time-consuming, but SmartCandidate
              streamlines the process with quick and easy document generation.
              In just a few steps, you’ll have customized application materials
              ready to go, increasing the number of opportunities you can
              pursue. Make a lasting impression on recruiters without the extra
              effort.
            </Typography>
          </Grid2>
          <Grid2 xs={6} maxWidth="300px">
            <img src={`${Time}`} height="200px" width="auto" alt="Success!" />
          </Grid2>
        </Grid2>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          padding="20px"
          justifyContent="space-evenly"
        >
          <Grid2 xs={6} maxWidth="300px" marginBottom="20px">
            <img src={`${Upload}`} height="200px" width="auto" alt="Success!" />
          </Grid2>
          <Grid2 xs={6}>
            <Typography
              fontFamily="General Sans Regular"
              sx={{ marginTop: "20px" }}
              variant="h3"
              fontSize="30px"
            >
              Effortless Personalization for Every Application
            </Typography>
            <Typography
              fontFamily="General Sans Regular"
              variant="body1"
              maxWidth="500px"
            >
              SmartCandidate takes the stress out of creating tailored job
              applications. By aligning your experiences and skills with the
              specific requirements of each position, it crafts professional
              cover letters that are impactful and relevant. Whether you’re
              switching fields or advancing in your career, SmartCandidate helps
              you present the best version of yourself.
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 container bgcolor="#f8f9fc" flexDirection="column" flexWrap="wrap">
        <Grid2 marginBottom="20px" marginTop="20px">
          <Typography
            fontFamily="General Sans Regular"
            variant="h3"
            fontWeight="700"
            fontSize="30px"
            textAlign="center"
          >
            Contact Us
          </Typography>
        </Grid2>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="column"
          alignItems="center"
          flexWrap="wrap"
          padding="20px"
          justifyContent="center"
        >
          <Grid2 xs={6} maxWidth="300px">
            <img src={`${Icon}`} height="200px" width="auto" alt="Success!" />
          </Grid2>
          <Grid2 xs={6} marginBottom="20px">
            <Typography
              fontFamily="General Sans Regular"
              sx={{ marginTop: "20px" }}
              variant="h3"
              fontSize="30px"
              textAlign="center"
            >
              SmartCandidate
            </Typography>
            <Typography
              fontFamily="General Sans Regular"
              variant="body1"
              maxWidth="500px"
              textAlign="center"
            >
              Unlock your career potential with SmartCandidate! Join us today to
              create customized, professional cover letters that set you apart.
              With tailored applications, you’re one step closer to landing your
              ideal job.
            </Typography>
            <Button
              type="submit"
              fullWidth
              href="/contact-us"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              Contact
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  );
};

export default landingPage;
